/*@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');*/
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

*{
  font-family: "Open Sans", sans-serif;
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}



.w-12 {
  width: 3rem;
}
.h-12 {
  height: 3rem;
}
audio, canvas, embed, iframe, img, object, svg, video {
  display: block;
  vertical-align: middle;
}
* {
  scrollbar-color: auto;
  scrollbar-width: auto;
}
