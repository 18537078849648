* {
    font-family: 'PS Commons', serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    position: static;
    width: 100%;
}

body {
    font-family: 'PS Commons', serif;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    /*  position: relative;*/
}

p {
    padding: 0;
    margin: 0;
}

.navbar {
    z-index: 1000;
}


.vp-h-25 {
    height: 25vh;
}

.vp-h-50 {
    height: 50vh;
}

.vp-h-75 {
    height: 75vh;
}

.vp-h-100 {
    height: 100vh;
}

.footer-link-p{
    margin-left: 2em;
    font-size: 0.8rem;
    color: rgba(243, 241, 241, 0.49);
    font-weight: normal;
}

.footer-link-p a{
    color: rgba(243, 241, 241, 0.49);
    text-decoration-color: rgba(243, 241, 241, 0.49);
}

.footer-link-p:hover,.footer-link-p a:hover{
    color:white
}

a:visited, a:focus, a:link, a:active {
    text-decoration: none;
    /* color: #fff;*/
}

#copyright-p{
    font-size: 0.8rem;
}

.footer-link-header{
    padding-bottom:1em;
}
/*   h1, h2 {
       font-weight: 200;
       margin: 0.4em 0;
   }

   h1 {
       font-size: 3.5em;
   }*/

.cd-jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
}

.bg-theme {
    background-color: #ff9000;
}

.theme-color {
    color: #ff9000;
}

.overlay-white {

}

.bg-black {
    background-color: black;
}


h2 {
    color: #888;
    font-size: 2em;
}


.partner-logo {
    position: relative;
    /* z-index: 500;*/
    width: 6em;
}

.partner-logo::before {


}

.partners-theme-text {
    font-size: 1rem;
}

.theme-alt-black {
    background-color: #0a0404;
}


.path-advert-card {
    position: relative;
}


.digi-kids-logo{
    height: 2em;
}

.path-advert-card-icon {
    position: absolute;
    width: 3em;
    top: 10px;
    right: 10px;
}

.path-key-text {
    font-size: 0.8rem;
}


.fixed-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
}

div.vanishing-dialog {
    margin: 0 5em;
}

/*h3 {
    height: 200px;
    width: 100%;
  !*  background-color: #666;*!
}*/

h1.display-3 {
    margin: auto 0;
    color: #fff;
}

.top-layer {
    height: 100vh;
    margin: 0;
    background-color: darkblue;
}

.action-buttons-block {
    align-self: center;
}


.inner-topic-text {

    font-size: 1.5rem;

}


@media screen and (min-width: 1200px) {
    .top {
        width: 100%;
        height: 100vh;

    }

    .partners-theme-text {
        font-size: 1rem;
    }

    .inner-topic-text {

        font-size: 1.5rem;

    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}

@media screen and (max-width: 1200px) {
    .top {
        width: 100%;
        height: 100vh;
    }

    div.vanishing-dialog h1 {
        font-size: 2em;
        text-align: center;
    }


    .partners-theme-text {
        font-size: 1rem;
    }

    .inner-topic-text {

        font-size: 1.5rem;

    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}

@media screen and (max-width: 992px) {

    .profile-text{

    }

    div.vanishing-dialog h1 {
        font-size: 1.9em;
        text-align: center;
    }

    .partners-theme-text {
        font-size: 0.9rem;
    }

    .inner-topic-text {

        font-size: 1.35rem;

    }

    .partner-logo {
        width: 5em;
        display: block;
    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}




code {
    font-family: Consolas,"courier new";
    color: crimson;
    padding: 2px;
    font-size: 105%;
}

.author-img{
    width: 8em;
}


.py-6{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.author-summary-p{
    line-height: 2em;
}