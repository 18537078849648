@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
* {
    font-family: 'Open Sans', serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/*
@font-face {
    font-family: 'PS Commons';
    font-style: normal;
    font-weight: normal;
    src: url('PS_TT_Commons_Regular.woff') format('woff');
}

@font-face {
    font-family: 'PS Commons Bold';
    font-style: normal;
    font-weight: normal;
    src: url('PS_TT_Commons_Bold.woff') format('woff');
}

@font-face {
    font-family: 'PS Commons Medium';
    font-style: normal;
    font-weight: normal;
    src: url('PS_TT_Commons_Medium.woff') format('woff');

}*/


html {
    position: static;
    width: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    /*  position: relative;*/
}

p {
    padding: 0;
    margin: 0;
}

.navbar {
    z-index: 1000;
}


.vp-h-25 {
    height: 25vh;
}

.vp-h-50 {
    height: 50vh;
}

.vp-h-75 {
    height: 75vh;
}

.vp-h-100 {
    height: 100vh;
}

.footer-link-p{
    margin-left: 2em;
    font-size: 0.8rem;
    color: rgba(243, 241, 241, 0.49);
    font-weight: normal;
    cursor: pointer;
}

.footer-link-p a{
    color: rgba(243, 241, 241, 0.49);
    text-decoration-color: rgba(243, 241, 241, 0.49);
}

.footer-link-p:hover,.footer-link-p a:hover{
    color:white
}

a:visited, a:focus, a:link, a:active {
    text-decoration: none;
    /* color: #fff;*/
}

#copyright-p{
    font-size: 0.8rem;
}

.footer-link-header{
    padding-bottom:1em;
    color: azure;
}
/*   h1, h2 {
       font-weight: 200;
       margin: 0.4em 0;
   }

   h1 {
       font-size: 3.5em;
   }*/

.cd-jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
}

.bg-theme {
    background-color: #ff9000;
}

.theme-color {
    color: #ff9000;
}

.overlay-white {

}

.bg-black {
    background-color: black;
}


h2 {
    color: #888;
    font-size: 2em;
}

.top {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    /*background-color: #666;*/
    /* background: url('image1.jpg') no-repeat top,linear-gradient(31deg, rgba(255,144,0,1) 20%, rgba(2,2,2,1) 50%);*/
   /* background: rgb(255, 144, 0);*/
    background: -moz-linear-gradient(270deg, rgb(255, 144, 0) 0%, rgb(0, 1, 9) 100%), url('image1.jpg') no-repeat top;
    background: -webkit-linear-gradient(270deg, rgb(255, 144, 0) 0%, rgb(0, 2, 12) 100%), url('image1.jpg') no-repeat top;
    background: linear-gradient(270deg, rgb(255, 144, 0) 0%, rgb(0, 1, 9) 100%), url('image1.jpg') no-repeat top;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#824b68", endColorstr="#303d81", GradientType=1);
    background-blend-mode: multiply;
    background-size: cover;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    height: 100vh;
    color: #fff;
}

.partner-logo {
   /* position: relative;*/
    /* z-index: 500;*/
    width: 2em;
}


.partners-theme-text {
    font-size: 1rem;
   /* text-align: center;*/
}

.theme-alt-black {
    background-color: #0a0404;
}


.path-advert-card {
    position: relative;
}


.recent-program-image {
    background-image: url("recent_event.jpg");
    height: 27em;
    background-blend-mode: multiply;
    background-size: cover;
    background-position-x: center;
}

.digi-kids-logo{
    height: 2em;
}

.path-advert-card-icon {
    position: absolute;
    width: 3em;
    top: 10px;
    right: 10px;
}

.path-key-text {
    font-size: 0.8rem;
}


.fixed-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
}

div.vanishing-dialog {
    margin: 0 5em;
}

/*h3 {
    height: 200px;
    width: 100%;
  !*  background-color: #666;*!
}*/

.freebies-text{
    font-family: "Open Sans", sans-serif;
    font-size: 0.9em;
}

h1.display-3 {
    margin: auto 0;
    color: #fff;
}

.top-layer {
    height: 100vh;
    margin: 0;
    background-color: darkblue;
}

.action-buttons-block {
    align-self: center;
}


.inner-topic-text {

    font-size: 1.5rem;

}


@media screen and (max-width: 576px) {

    div.vanishing-dialog h1{
        font-size: 1.2em;
        text-align: center;
    }

    .top {
        width: 100%;
        /* fallback */
        background: rgba(255, 144, 0, 1) url("image1.jpg"), linear-gradient(#ff9000, #000000);
        background-blend-mode: multiply;
        background-position-x: center;
    }

    .recent-program-image {
        display: none;
    }

    .partner-logo {
        width: 3em;
        /* display: block;*/
    }

    .partners-theme-text {
        font-size: 0.7rem;
    }

    .inner-topic-text {
        font-size: 1.05rem;
    }

    div.vanishing-dialog h1 {
        font-size: 1.2em;
        text-align: center;
    }

}

@media screen and (min-width: 1200px) {


    .top {
        width: 100%;
        height: 100vh;

    }

    .partners-theme-text {
        font-size: 1rem;
    }

    .inner-topic-text {

        font-size: 1.5rem;

    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}

@media screen and (max-width: 1200px) {
    .top {
        width: 100%;
        height: 100vh;
    }

    div.vanishing-dialog h1 {
        font-size: 2em;
        text-align: center;
    }


    .partners-theme-text {
        font-size: 1rem;
    }

    .inner-topic-text {

        font-size: 1.5rem;

    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}

@media screen and (max-width: 992px) {


    div.vanishing-dialog h1 {
        font-size: 1.9em;
        text-align: center;
    }

    .partners-theme-text {
        font-size: 0.9rem;
    }

    .inner-topic-text {

        font-size: 1.35rem;

    }

    .partner-logo {
        width: 5em;
        /*display: block;*/
    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}

@media screen and (max-width: 768px) {
    .top {
        width: 100%;
        /* fallback */
        background: rgba(255, 144, 0, 1) url("image1.jpg"), linear-gradient(#ff9000, #000000);
        background-blend-mode: multiply;
        background-position-x: center;
    }

    .partners-theme-text {
        font-size: 0.8rem;
        text-align: center;
    }

    .inner-topic-text {

        font-size: 1.2rem;

    }

    .partner-logo {
        width: 4em;
      /*  display: block;*/
    }

    div.vanishing-dialog h1 {
        font-size: 1.6em;
        text-align: center;
    }

    h1.display-3 {
        margin: auto 0;
        color: #fff;
    }
}




code {
    font-family: Consolas,"courier new";
    color: crimson;
    padding: 2px;
    font-size: 105%;
}


/*Below you find Sliding Partners CSS*/

/* Slider */
.slick-slide {
    margin: 0px 20px;
}

.logo-carousel {
    overflow: inherit;
   /* border-top: 1px solid #353535;
    border-bottom: 1px solid #353535;*/
}

.slick-slide img {
    width: 100%;
}

.slick-track::before,
.slick-track::after {
    display: table;
    content: '';
}

.slick-track::after {
    clear: both;
}

.slick-track {
    padding: 1rem 0;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    background: url(https://raw.githubusercontent.com/solodev/infinite-logo-carousel/master/images/arrow.svg?sanitize=true) center no-repeat;
    color: #fff;
    filter: invert(77%) sepia(32%) saturate(1%) hue-rotate(344deg) brightness(105%) contrast(103%);
    border: none;
    width: 2rem;
    height: 1.5rem;
    text-indent: -10000px;
    margin-top: -16px;
    z-index: 99;
}

.slick-arrow.slick-next {
    right: -40px;
    transform: rotate(180deg);
}

.slick-arrow.slick-prev {
    left: -40px;
}

/* Media Queries */

@media (max-width: 768px) {
    .slick-arrow {
        width: 1rem;
        height: 1rem;
    }
}

.row {
    overflow: hidden;
}

/* JsFiddle Example only/don't use */
.logo-carousel {
    margin-top: 32px;
}